<template>
    <footer class="gw-footer">
        <div v-if="showContact && salesContact.contact && salesContact.contact.length" class="gw-footer__contact-wrapper">
            <gw-page-container class="gw-page-container--content">
                <gw-cta-card image-alignment="right">
                    <h3 class="gw-footer__title">{{ salesContact.heading }}</h3>
                    <p class="gw-footer__contact-body">{{ salesContact.description }}</p>
                    <div class="gw-footer__contact-footer">
                        <div>{{ salesContact.contact[0].phone }}</div>
                        <div>{{ salesContact.contact[0].email }}</div>
                        <a
                            class="gw-footer__link"
                            :href="salesContact.contact[0].externalLink"
                            target="_blank"
                            data-ga-category="contact"
                            data-ga-action="click-contact"
                            :data-label="$route.path"
                        >
                            {{ $t('nav.schedule-meeting') }}
                        </a>
                    </div>
                    <template v-if="salesContact.contact[0].image.length" slot="image">
                        <lazy-img
                            class="lazy-img--cover lazy-img--dark"
                            :src="salesContact.contact[0].image[0].url"
                            :src-tiny="salesContact.contact[0].image[0].url_tiny"
                            :width="salesContact.contact[0].image[0].width"
                            :height="salesContact.contact[0].image[0].height"
                        />
                    </template>
                </gw-cta-card>
            </gw-page-container>
        </div>
        <div class="gw-footer__wrapper">
            <gw-page-container>
                <div class="gw-footer__grid">
                    <div class="gw-footer__item" data-order="1">
                        <h3 class="gw-footer__title">
                            <a class="gw-footer__link" :href="localePath('/courses')">
                                {{ $t('footer.courses') }}
                            </a>
                        </h3>
                        <gw-nav
                            class="gw-nav--tight"
                            :items="footerMainMenu"
                        />
                    </div>
                    <div class="gw-footer__item" data-order="2">
                        <h3 class="gw-footer__title">
                            <a class="gw-footer__link" :href="localePath('/gladwell-academy')">
                                {{ $t('footer.gladwell-academy') }}
                            </a>
                        </h3>
                        <gw-nav
                            class="gw-nav--tight"
                            :items="footerSecondaryMenu"
                        />
                    </div>
                    <div class="gw-footer__item" data-order="3">
                        <h3 class="gw-footer__title">
                            <a class="gw-footer__link" :href="localePath('/knowledge')">
                                {{ $t('footer.knowledge-hub') }}
                            </a>
                        </h3>
                        <gw-nav
                            class="gw-nav--tight gw-nav--truncate"
                            :items="footerTertiaryMenu"
                        />
                    </div>
                    <div
                        class="gw-footer__item gw-footer__item--secondary"
                        data-order="4"
                    >
                        <div>
                            {{ $t('footer.copyright', { year: new Date().getFullYear() }) }}
                        </div>
                        <img class="gw-footer__logo" src="/logos/adyen-logo.svg" alt="Adyen logo">
                    </div>
                    <div
                        class="gw-footer__item gw-footer__item--secondary"
                        data-order="5"
                    >
                        <gw-nav
                            class="gw-nav--tight"
                            :items="footerSubMenu"
                        >
                            <template slot="custom-item">
                                <gw-currency-switch class="gw-footer__currency-switcher" />
                            </template>
                        </gw-nav>
                    </div>
                </div>
            </gw-page-container>
        </div>
    </footer>
</template>

<script>
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwCtaCard from '~/patterns/atoms/card/presets/cta-card.vue';
import GwNav from '~/patterns/molecules/nav/nav.vue';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';
import GwCurrencySwitch from '~/patterns/molecules/currency-switch/currency-switch.vue';

import getFooterMenu from '~/graphql/queries/getFooterMenu.graphql';

export default {
    components: {
        GwPageContainer,
        GwCtaCard,
        LazyImg,
        GwNav,
        GwCurrencySwitch
    },
    props: {
        showContact: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            footerMainMenu: [],
            footerSecondaryMenu: [],
            footerTertiaryMenu: [],
            footerSubMenu: [],
            salesContact: [],
            image: {
                url: '/images/wessel-van-dongen.jpeg',
                url_tiny: '/images/wessel-van-dongen.jpeg',
                width: 800,
                height: 800
            }
        };
    },
    async fetch() {
        const site = this.$config.appMultisiteHandle,
            { data } = await this.$gql.executeQuery(getFooterMenu, { site });

        if (data && data.globalSets.length > 0) {
            // Map globalSets to data
            data.globalSets.forEach((set) => {
                if (this[set.handle] && set.entryLinks) {
                    this[set.handle] = set.entryLinks;
                } else if (this[set.handle] && set.contact) {
                    this[set.handle] = set;
                }
            });
        }
    },
};
</script>

<style lang="less" src="./footer.less" />
