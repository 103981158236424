<template>
    <div class="gw-backdrop">
        <slot />

        <img
            v-if="backdropType === 'quote'"
            class="gw-backdrop__graphic"
            src="/graphics/quote-backdrop.svg"
        >
        <img
            v-if="backdropType === 'home'"
            class="gw-backdrop__graphic"
            src="/graphics/home-backdrop.svg"
        >
    </div>
</template>

<script>
export default {
    props: {
        backdropType: {
            type: String,
            default: 'quote'
        }
    }
};
</script>

<style src="./backdrop.less" lang="less"></style>
