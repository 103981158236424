<template>
    <div
        class="gw-expander"
        :class="{
            'gw-expander--expanded': expanded
        }"
    >
        <gw-button
            v-if="!!$slots.trigger_toggle"
            class="dn-button--label gw-expander__trigger"
            @click="toggleExpansion"
        >
            <slot name="trigger_toggle" />
            <gw-icon v-if="expanded" :icon="iconMinimize" />
            <gw-icon v-else :icon="iconMaximize" />
        </gw-button>

        <div class="gw-expander__content">
            <slot name="content" />
        </div>

        <gw-button
            v-if="expanded && !!$slots.trigger_close"
            class="dn-button--label gw-expander__trigger"
            @click="toggleExpansion"
        >
            <slot name="trigger_close" />
            <gw-icon :icon="iconMinimize" />
        </gw-button>

        <gw-button
            v-if="!expanded && !!$slots.trigger_open"
            class="dn-button--label gw-expander__trigger"
            @click="toggleExpansion"
        >
            <slot name="trigger_open" />
            <gw-icon :icon="iconMaximize" />
        </gw-button>
    </div>
</template>

<script>
import GwButton from '~/patterns/atoms/button/button.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        GwButton,
        GwIcon
    },
    props: {
        iconMaximize: {
            type: String,
            default: 'chevron-down'
        },
        iconMinimize: {
            type: String,
            default: 'chevron-up'
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: false
        };
    },
    mounted() {
        this.expanded = this.isOpen;
    },
    methods: {
        toggleExpansion() {
            this.expanded = !this.expanded;
        }
    }
};
</script>

<style src="./expander.less" lang="less"></style>
