<template>
    <gw-block v-if="contact" class="gw-nav-block">
        <h4 slot="title"><slot name="title" /></h4>
        <template slot="body"><slot name="body" /></template>

        <div slot="footer" class="gw-block__media">
            <gw-avatar
                v-if="contact.image"
                :image="contact.image[0]"
                :alt="contact.title"
            />
            <div class="gw-block__media-text">
                <div v-if="contact.phone" class="gw-block__contact">{{ contact.phone }}</div>
                <div v-if="contact.email" class="gw-block__contact">{{ contact.email }}</div>
                <div>
                    <a
                        v-if="contact.externalLink"
                        :href="contact.externalLink"
                        target="_blank"
                        data-ga-category="contact"
                        data-ga-action="click-contact"
                        :data-label="$route.path"
                    >
                        {{ $t('nav.schedule-meeting') }}
                    </a>
                </div>
            </div>
        </div>
    </gw-block>
</template>

<script>
import GwBlock from '~/patterns/atoms/block/block.vue';
import GwAvatar from '~/patterns/atoms/avatar/avatar.vue';

export default {
    components: {
        GwBlock,
        GwAvatar
    },
    props: {
        contact: {
            type: Object,
            default: () => { return {}; }
        }
    },
    data() {
        return {
            image: {
                url: '/images/wessel-van-dongen.jpeg',
                url_tiny: '/images/wessel-van-dongen.jpeg',
                width: 800,
                height: 800
            }
        };
    }
};
</script>

<style lang="less" src="./nav-block.less" />
