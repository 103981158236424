<template>
    <div class="layout">
        <gw-header :elements="['contact']" />

        <nuxt class="layout__main" />
    </div>
</template>

<script>
import favicons from '~/mixins/favicons';
import GwHeader from '~/patterns/organisms/header/header.vue';

export default {
    components: {
        GwHeader,
    },
    mixins: [favicons],
    head() {
        return {
            meta: [
                ...this.faviconMeta
            ],
            link: [
                ...this.faviconLink
            ]
        };
    },
};
</script>

<style lang="less" scoped>
    .layout {
        .theme-light();
    }
</style>
