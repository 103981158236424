<template>
    <div class="gw-avatar" :title="alt">
        <div class="gw-avatar__wrapper">
            <gw-lazy-img
                v-if="hasImage"
                class="gw-avatar__image lazy-img--cover"
                :src="image.url"
                :src-tiny="image.url_tiny"
                :width="image.width"
                :height="image.height"
                :alt="alt"
            />
            <div v-else class="gw-avatar__placeholder">
                <div v-if="alt">
                    {{ getInitials(alt) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GwLazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';

export default {
    components: {
        GwLazyImg
    },
    props: {
        image: {
            type: Object,
            default: () => { return {}; }
        },
        staticUrl: {
            type: String,
            default: ''
        },
        alt: {
            type: String,
            default: null
        }
    },
    computed: {
        hasImage() {
            return Object.keys(this.image).length > 0;
        }
    },
    methods: {
        getInitials(text) {
            return text.match(/\b(\w)/g).join('');
        }
    }
};
</script>

<style src="./avatar.less" lang="less"></style>
