<template>
    <div class="template">
        <slot />

        <client-only>
            <gw-cookie-bar />
        </client-only>
    </div>
</template>

<script>
import GwCookieBar from '~/patterns/organisms/cookie-bar/cookie-bar.vue';

export default {
    components: {
        GwCookieBar
    }
};
</script>
