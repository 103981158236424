import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _100010f0 = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _93cd51ac = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _0b07552c = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _281944ce = () => interopDefault(import('../pages/editions/index.vue' /* webpackChunkName: "pages/editions/index" */))
const _24d19125 = () => interopDefault(import('../pages/events-and-webinars/index.vue' /* webpackChunkName: "pages/events-and-webinars/index" */))
const _27ca8642 = () => interopDefault(import('../pages/trainers/index.vue' /* webpackChunkName: "pages/trainers/index" */))
const _555f8a53 = () => interopDefault(import('../pages/tests/index.vue' /* webpackChunkName: "pages/tests/index" */))
const _32fe0f60 = () => interopDefault(import('../pages/checkout/confirmation/index.vue' /* webpackChunkName: "pages/checkout/confirmation/index" */))
const _1be88928 = () => interopDefault(import('../pages/knowledge/q-and-a/_slug.vue' /* webpackChunkName: "pages/knowledge/q-and-a/_slug" */))
const _0e7497bc = () => interopDefault(import('../pages/courses/_slug.vue' /* webpackChunkName: "pages/courses/_slug" */))
const _231aefdd = () => interopDefault(import('../pages/events-and-webinars/_slug.vue' /* webpackChunkName: "pages/events-and-webinars/_slug" */))
const _2613e4fa = () => interopDefault(import('../pages/trainers/_slug.vue' /* webpackChunkName: "pages/trainers/_slug" */))
const _0814a2f6 = () => interopDefault(import('../pages/knowledge/_category/_slug.vue' /* webpackChunkName: "pages/knowledge/_category/_slug" */))
const _87edd220 = () => interopDefault(import('../pages/courses/_category/_slug.vue' /* webpackChunkName: "pages/courses/_category/_slug" */))
const _99ff8bfe = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _13f5e68e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _100010f0,
    name: "knowledge___fr"
  }, {
    path: "/checkout",
    component: _93cd51ac,
    name: "checkout___en"
  }, {
    path: "/checkout",
    component: _93cd51ac,
    name: "checkout___nl"
  }, {
    path: "/checkout",
    component: _93cd51ac,
    name: "checkout___fr"
  }, {
    path: "/checkout",
    component: _93cd51ac,
    name: "checkout___de"
  }, {
    path: "/checkout",
    component: _93cd51ac,
    name: "checkout___en-IN"
  }, {
    path: "/courses",
    component: _0b07552c,
    name: "courses___en"
  }, {
    path: "/courses",
    component: _0b07552c,
    name: "courses___en-IN"
  }, {
    path: "/editions",
    component: _281944ce,
    name: "editions___en"
  }, {
    path: "/editions",
    component: _281944ce,
    name: "editions___nl"
  }, {
    path: "/editions",
    component: _281944ce,
    name: "editions___fr"
  }, {
    path: "/editions",
    component: _281944ce,
    name: "editions___de"
  }, {
    path: "/editions",
    component: _281944ce,
    name: "editions___en-IN"
  }, {
    path: "/evenements-et-webinars",
    component: _24d19125,
    name: "events-and-webinars___fr"
  }, {
    path: "/events-and-webinars",
    component: _24d19125,
    name: "events-and-webinars___en"
  }, {
    path: "/events-and-webinars",
    component: _24d19125,
    name: "events-and-webinars___nl"
  }, {
    path: "/events-and-webinars",
    component: _24d19125,
    name: "events-and-webinars___en-IN"
  }, {
    path: "/events-und-webinars",
    component: _24d19125,
    name: "events-and-webinars___de"
  }, {
    path: "/formateurs",
    component: _27ca8642,
    name: "trainers___fr"
  }, {
    path: "/formations",
    component: _0b07552c,
    name: "courses___fr"
  }, {
    path: "/knowledge",
    component: _100010f0,
    name: "knowledge___en"
  }, {
    path: "/knowledge",
    component: _100010f0,
    name: "knowledge___nl"
  }, {
    path: "/knowledge",
    component: _100010f0,
    name: "knowledge___de"
  }, {
    path: "/knowledge",
    component: _100010f0,
    name: "knowledge___en-IN"
  }, {
    path: "/tests",
    component: _555f8a53,
    name: "tests___en"
  }, {
    path: "/tests",
    component: _555f8a53,
    name: "tests___nl"
  }, {
    path: "/tests",
    component: _555f8a53,
    name: "tests___fr"
  }, {
    path: "/tests",
    component: _555f8a53,
    name: "tests___de"
  }, {
    path: "/tests",
    component: _555f8a53,
    name: "tests___en-IN"
  }, {
    path: "/trainer",
    component: _27ca8642,
    name: "trainers___de"
  }, {
    path: "/trainers",
    component: _27ca8642,
    name: "trainers___en"
  }, {
    path: "/trainers",
    component: _27ca8642,
    name: "trainers___nl"
  }, {
    path: "/trainers",
    component: _27ca8642,
    name: "trainers___en-IN"
  }, {
    path: "/trainingen",
    component: _0b07552c,
    name: "courses___nl"
  }, {
    path: "/trainings",
    component: _0b07552c,
    name: "courses___de"
  }, {
    path: "/checkout/confirmation",
    component: _32fe0f60,
    name: "checkout-confirmation___en"
  }, {
    path: "/checkout/confirmation",
    component: _32fe0f60,
    name: "checkout-confirmation___nl"
  }, {
    path: "/checkout/confirmation",
    component: _32fe0f60,
    name: "checkout-confirmation___fr"
  }, {
    path: "/checkout/confirmation",
    component: _32fe0f60,
    name: "checkout-confirmation___de"
  }, {
    path: "/checkout/confirmation",
    component: _32fe0f60,
    name: "checkout-confirmation___en-IN"
  }, {
    path: "/articles/q-and-a/:slug",
    component: _1be88928,
    name: "knowledge-q-and-a-slug___fr"
  }, {
    path: "/knowledge/q-and-a/:slug",
    component: _1be88928,
    name: "knowledge-q-and-a-slug___en"
  }, {
    path: "/knowledge/q-and-a/:slug",
    component: _1be88928,
    name: "knowledge-q-and-a-slug___nl"
  }, {
    path: "/knowledge/q-and-a/:slug",
    component: _1be88928,
    name: "knowledge-q-and-a-slug___de"
  }, {
    path: "/knowledge/q-and-a/:slug?",
    component: _1be88928,
    name: "knowledge-q-and-a-slug___en-IN"
  }, {
    path: "/courses/:slug",
    component: _0e7497bc,
    name: "courses-slug___en"
  }, {
    path: "/courses/:slug",
    component: _0e7497bc,
    name: "courses-slug___en-IN"
  }, {
    path: "/evenements-et-webinars/:slug",
    component: _231aefdd,
    name: "events-and-webinars-slug___fr"
  }, {
    path: "/events-and-webinars/:slug",
    component: _231aefdd,
    name: "events-and-webinars-slug___en"
  }, {
    path: "/events-and-webinars/:slug",
    component: _231aefdd,
    name: "events-and-webinars-slug___nl"
  }, {
    path: "/events-and-webinars/:slug?",
    component: _231aefdd,
    name: "events-and-webinars-slug___en-IN"
  }, {
    path: "/events-und-webinars/:slug",
    component: _231aefdd,
    name: "events-and-webinars-slug___de"
  }, {
    path: "/formateurs/:slug",
    component: _2613e4fa,
    name: "trainers-slug___fr"
  }, {
    path: "/formations/:slug",
    component: _0e7497bc,
    name: "courses-slug___fr"
  }, {
    path: "/trainer/:slug",
    component: _2613e4fa,
    name: "trainers-slug___de"
  }, {
    path: "/trainers/:slug",
    component: _2613e4fa,
    name: "trainers-slug___en"
  }, {
    path: "/trainers/:slug",
    component: _2613e4fa,
    name: "trainers-slug___nl"
  }, {
    path: "/trainers/:slug",
    component: _2613e4fa,
    name: "trainers-slug___en-IN"
  }, {
    path: "/trainingen/:slug",
    component: _0e7497bc,
    name: "courses-slug___nl"
  }, {
    path: "/trainings/:slug",
    component: _0e7497bc,
    name: "courses-slug___de"
  }, {
    path: "/articles/:category/:slug",
    component: _0814a2f6,
    name: "knowledge-category-slug___fr"
  }, {
    path: "/courses/:category/:slug",
    component: _87edd220,
    name: "courses-category-slug___en"
  }, {
    path: "/courses/:category/:slug?",
    component: _87edd220,
    name: "courses-category-slug___en-IN"
  }, {
    path: "/formations/:category/:slug",
    component: _87edd220,
    name: "courses-category-slug___fr"
  }, {
    path: "/knowledge/:category/:slug",
    component: _0814a2f6,
    name: "knowledge-category-slug___en"
  }, {
    path: "/knowledge/:category/:slug",
    component: _0814a2f6,
    name: "knowledge-category-slug___nl"
  }, {
    path: "/knowledge/:category/:slug",
    component: _0814a2f6,
    name: "knowledge-category-slug___de"
  }, {
    path: "/knowledge/:category/:slug?",
    component: _0814a2f6,
    name: "knowledge-category-slug___en-IN"
  }, {
    path: "/trainingen/:category/:slug",
    component: _87edd220,
    name: "courses-category-slug___nl"
  }, {
    path: "/trainings/:category/:slug",
    component: _87edd220,
    name: "courses-category-slug___de"
  }, {
    path: "/",
    component: _99ff8bfe,
    name: "index___en"
  }, {
    path: "/",
    component: _99ff8bfe,
    name: "index___nl"
  }, {
    path: "/",
    component: _99ff8bfe,
    name: "index___fr"
  }, {
    path: "/",
    component: _99ff8bfe,
    name: "index___de"
  }, {
    path: "/",
    component: _99ff8bfe,
    name: "index___en-IN"
  }, {
    path: "/*",
    component: _13f5e68e,
    name: "all___en-IN"
  }, {
    path: "/*",
    component: _13f5e68e,
    name: "all___de"
  }, {
    path: "/*",
    component: _13f5e68e,
    name: "all___fr"
  }, {
    path: "/*",
    component: _13f5e68e,
    name: "all___nl"
  }, {
    path: "/*",
    component: _13f5e68e,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
