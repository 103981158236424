<template>
    <gw-modal
        class="gw-cookie-modal"
        :is-visible="showModal"
        @close="closeModal"
    >
        <template slot="close-button">
            <a
                class="dn-modal__close"
                @click="closeModal"
            >
                <gw-icon icon="cross" />
            </a>
        </template>
        <h3>
            {{ $t('cookiebar.preferences.title') }}
        </h3>
        <p>
            <i18n path="cookiebar.preferences.description">
                <template v-slot:link>
                    <nuxt-link to="/privacy-statement">{{ $t('cookiebar.preferences.link') }}</nuxt-link>
                </template>
            </i18n>
        </p>
        <h4>
            {{ $t('cookiebar.preferences.subtitle') }}
        </h4>
        <dn-cookie-preferences class="gw-cookie-preferences" @submit.native.prevent="$cookieConsent.save">
            <template slot="category_functional">
                <div class="gw-cookie-modal__option">
                    <em>{{ $t('cookiebar.functional.title') }}</em>
                    {{ $t('cookiebar.functional.description') }}
                </div>
            </template>

            <template v-slot:category_analytical>
                <div class="gw-cookie-modal__option">
                    <em>{{ $t('cookiebar.analytical.title') }}</em>
                    {{ $t('cookiebar.analytical.description') }}
                </div>
            </template>

            <template v-slot:category_social>
                <div class="gw-cookie-modal__option">
                    <em>{{ $t('cookiebar.social.title') }}</em>
                    {{ $t('cookiebar.social.description') }}
                </div>
            </template>

            <template v-slot:category_marketing>
                <div class="gw-cookie-modal__option">
                    <em>{{ $t('cookiebar.marketing.title') }}</em>
                    {{ $t('cookiebar.marketing.description') }}
                </div>
            </template>

            <template v-slot:category_all>
                <div class="gw-cookie-modal__option">
                    <em>{{ $t('cookiebar.all.title') }}</em>
                    {{ $t('cookiebar.all.description') }}
                </div>
            </template>

            <template v-slot:category_trigger="{ category }">
                <gw-checkbox
                    :id="category.id"
                    v-model="$cookieConsent.cookieVm.checkedCategories"
                    :value="category.id"
                    :name="category.id"
                    :disabled="category.required"
                />
            </template>
        </dn-cookie-preferences>

        <gw-button
            type="submit"
            :data-loading="isSaving"
            class="dn-button--primary dn-button--large"
            @click="save"
        >
            <template v-if="isSaving">Saving...</template>
            <template v-else>{{ $t('cookiebar.preferences.save') }}</template>
        </gw-button>
    </gw-modal>
</template>

<script>
import { DnCookiePreferences } from '@digitalnatives/cookie-consent';
import GwModal from '~/patterns/atoms/modal/modal.vue';
import GwCheckbox from '~/patterns/atoms/checkbox/checkbox.vue';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        DnCookiePreferences,
        GwCheckbox,
        GwButton,
        GwModal,
        GwIcon
    },

    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            preferences: {}
        };
    },

    computed: {
        isSaving() {
            return this.$cookieConsent.isSaving();
        },
        checked() {
            return this.$cookieConsent.cookieVm.checkedCategories;
        }
    },

    beforeMount() {
        this.preferences = [...this.$cookieConsent.cookieVm.checkedCategories];
    },

    mounted() {
        // Workaround for HS, opt out of tracking by default
        if (!this.$cookieConsent.isCookiebarDismissed()) {
            this.setHubspotOptOut('yes');
        }
    },

    methods: {
        save() {
            // If marketing cookies are accepted, remove HS opt out
            if (this.checked.includes('marketing')) {
                this.setHubspotOptOut('no');
            }

            this.$cookieConsent.save();

            this.$emit('closeModal');
        },

        closeModal() {
            const confirmed = confirm(this.$t('cookiebar.preferences.confirm'));

            if (confirmed) {
                this.save();
            }
        },

        setHubspotOptOut(value) {
            const d = new Date();
            const exdays = 365;

            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            const expires = d.toUTCString();
            document.cookie = `__hs_do_not_track=${value}; expires=${expires};path=/`;
        }
    },

};
</script>

<style lang="less" src="./cookie-modal.less"></style>
