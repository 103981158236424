<template>
    <nav class="gw-nav">
        <ul v-if="items.length > 0 || !!$slots['custom-item']" class="gw-nav__links">
            <li v-for="item in items" :key="item.linkTitle">
                <template v-if="item.linkEntry && item.linkEntry.length > 0">
                    <nuxt-link
                        v-for="(link, index) in item.linkEntry"
                        :key="index"
                        class="gw-nav__link"
                        :to="`/${link.uri}`"
                    >
                        {{ item.linkTitle }}
                    </nuxt-link>
                </template>

                <a
                    v-else-if="item.linkURL"
                    class="gw-nav__link"
                    :href="item.linkURL"
                    :target="item.linkTargetBlank ? '_blank' : ''"
                >
                    {{ item.linkTitle }}
                </a>
            </li>
            <li v-if="!!$slots['custom-item']">
                <slot name="custom-item" />
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => { return []; }
        }
    }
};
</script>

<style src="./nav.less" lang="less"></style>
