import { trimEnd } from 'lodash';

export default function({ route, redirect }) {
    if (process && process.env && process.env.APP_MULTISITE_HANDLE) {
        const redirects = require(`./redirects-${process.env.APP_MULTISITE_HANDLE}.json`),
            /** @type {string} routePath */
            routePath = trimEnd(route.path, '/'),
            /** @type {object} foundRedirect */
            foundRedirect = redirects.find((currentRedirect) => {
                if (currentRedirect.old === currentRedirect.new) {
                    return false;
                }

                if (currentRedirect.old === routePath) {
                    return true;
                }

                if (new RegExp(`^${currentRedirect.old}$`, 'i').test(routePath)) {
                    return true;
                }

                return false;
            });

        if (foundRedirect) {
            return redirect(301, foundRedirect.new);
        }
    }
}
