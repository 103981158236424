<template>
    <div>
        <gw-cookie-modal :show-modal="showModal" @closeModal="handleClose" />

        <dn-cookiebar class="gw-cookie-bar" role="alert">
            <template v-slot="{ acceptAllCookies }">
                <h3 class="gw-cookie-bar__title">{{ $t('cookiebar.title') }}</h3>

                <div class="gw-cookie-bar__wrapper">
                    <div>
                        <p class="gw-cookie-bar__message">
                            <i18n path="cookiebar.message">
                                <template v-slot:privacy>
                                    <nuxt-link :to="getPrivacyUrl()">{{ $t('cookiebar.link') }}</nuxt-link>
                                </template>
                            </i18n>
                        </p>
                    </div>

                    <div class="gw-cookie-bar__button-wrapper">
                        <gw-button
                            class="gw-cookiebar__accept dn-button--secondary dn-button--large"
                            :data-loading="isSaving"
                            @click="openPreferences"
                        >
                            <span v-if="isSaving">{{ $t('cookiebar.saving') }}</span>
                            <span v-else>{{ $t('cookiebar.configure-preferences') }}</span>
                        </gw-button>

                        <gw-button
                            class="gw-cookiebar__accept dn-button--primary dn-button--large"
                            :data-loading="isSaving"
                            @click="acceptAllCookies(); setHubspotOptOut('no');"
                        >
                            <span v-if="isSaving">{{ $t('cookiebar.saving') }}</span>
                            <span v-else>{{ $t('cookiebar.accept') }}</span>
                        </gw-button>
                    </div>
                </div>
            </template>
        </dn-cookiebar>
    </div>
</template>

<script>
import { DnCookiebar } from '@digitalnatives/cookie-consent';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwCookieModal from '~/patterns/organisms/cookie-modal/cookie-modal.vue';

export default {
    components: {
        DnCookiebar,
        GwButton,
        GwCookieModal
    },

    data() {
        return {
            showModal: false
        };
    },

    computed: {
        isCookiebarHidden() {
            return false;
        },
    },
    methods: {
        openPreferences() {
            this.showModal = true;
        },
        handleClose() {
            this.showModal = false;
        },
        getPrivacyUrl() {
            const locale = this.$config.appMultisiteHandle;
            const localizedUrls = {
                en: '/privacy-statement',
                nl: '/privacy-verklaring',
                fr: '/politique-de-confidentialite',
                de: '/datenschutzerklaerung',
                in: '/privacy-statement'
            };

            return localizedUrls[locale];
        },
        setHubspotOptOut(value) {
            const d = new Date();
            const exdays = 365;

            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            const expires = d.toUTCString();
            document.cookie = `__hs_do_not_track=${value}; expires=${expires};path=/`;
        }
    }
};
</script>

<style lang="less" src="./cookie-bar.less"></style>
