<template>
    <gw-expander ref="expander" class="gw-currency-switch">
        <template slot="trigger_toggle">
            {{ $t('nav.currency') }}: {{ selectedCurrency }} {{ getCurrencySymbol(selectedCurrency) }}
        </template>

        <template slot="content">
            <div class="gw-currency-switch__content">
                <div
                    v-for="currency in currencies"
                    :key="currency"
                    class="gw-currency-switch__item"
                >
                    <gw-button
                        class="dn-button--label"
                        @click="updateCurrency(currency)"
                    >
                        <gw-icon slot="pre" icon="arrow-right" /> {{ currency }} {{ getCurrencySymbol(currency) }}
                    </gw-button>
                </div>
            </div>
        </template>
    </gw-expander>
</template>

<script>
import GwExpander from '~/patterns/molecules/expander/expander.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        GwExpander,
        GwIcon,
        GwButton
    },
    data() {
        return {
            selectedCurrency: null,
            currencies: this.$config.site.currencies,
        };
    },
    watch: {
        selectedCurrency() {
            if (process.client && localStorage.getItem('selectedCurrency') !== this.selectedCurrency) {
                localStorage.setItem('selectedCurrency', this.selectedCurrency);
                this.$nuxt.$emit('selectedCurrency');
            }
        }
    },
    mounted() {
        this.getCurrency();
        this.$nuxt.$on('selectedCurrency', () => {
            if (this.selectedCurrency !== localStorage.getItem('selectedCurrency')) {
                this.selectedCurrency = localStorage.getItem('selectedCurrency');
            }
        });
    },
    methods: {
        updateCurrency(currency) {
            this.selectedCurrency = currency;

            this.$refs.expander.toggleExpansion();
        },
        getCurrency() {
            if (process.client) {
                this.selectedCurrency = localStorage.getItem('selectedCurrency') || this.$config.site.currencies[0];
            }
        },
        getCurrencySymbol(currency) {
            if (currency === 'EUR') {
                return '(€)';
            } else if (currency === 'USD') {
                return '($)';
            } else if (currency === 'INR') {
                return '(₹)';
            } else {
                return '';
            }
        }
    }
};
</script>

<style src="./currency-switch.less" lang="less"></style>
