import Vue from 'vue';
import VueCookieConsent from '@digitalnatives/cookie-consent';

Vue.use(VueCookieConsent, {
    categories: [
        { id: 'functional', required: true },
        { id: 'analytical', required: false },
        { id: 'marketing', required: false }
    ],
});
