<template>
    <a v-if="href" class="gw-card" :href="href">
        <slot name="before" />
        <div v-if="!!$slots.image" class="gw-card__image">
            <slot name="image" />
        </div>
        <div class="gw-card__body">
            <h3 v-if="!!$slots.title" class="gw-card__title">
                <slot name="title" />
            </h3>

            <slot />
        </div>
        <slot name="after" />
    </a>
    <div v-else class="gw-card" :href="href">
        <slot name="before" />
        <div v-if="!!$slots.image" class="gw-card__image">
            <slot name="image" />
        </div>
        <div class="gw-card__body">
            <h3 v-if="!!$slots.title" class="gw-card__title">
                <slot name="title" />
            </h3>

            <slot />
        </div>
        <slot name="after" />
    </div>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
            default: null
        }
    }
};
</script>

<style src="./card.less" lang="less"></style>
