<template>
    <div
        class="gw-navigation"
        :class="{
            'gw-navigation--dark-mode': darkMode
        }"
    >
        <div
            v-if="showContact && headerContactInfo.phone"
            class="gw-navigation__contact"
            :aria-hidden="`${open}`"
        >
            {{ $t('nav.question') }}
            <a :href="`tel:${formatPhone(headerContactInfo.phone)}`" class="gw-navigation__phone">
                {{ headerContactInfo.phone }}
            </a>
        </div>
        <gw-nav
            v-if="showTertiaryNav"
            class="gw-navigation__tertiary-nav gw-nav--inline"
            :aria-hidden="`${open}`"
            :items="tertiaryMenu"
        />
        <div
            v-if="showEventButton"
            class="gw-navigation__button"
        >
            <gw-button
                v-scroll-to="{
                    el: '#item-with-cta',
                    offset: -200
                }"
                element="a"
            >
                {{ $t('event.get-tickets') }}
            </gw-button>
        </div>
        <template v-if="showNavigation">
            <a class="gw-navigation__trigger" @click="toggleOverlay()">
                <gw-icon v-if="open" icon="cross" />
                <gw-icon v-else icon="menu" />
            </a>

            <div class="gw-navigation__overlay" :aria-hidden="`${!open}`">
                <div class="gw-navigation__header-spacer" />

                <div class="gw-navigation__wrapper">
                    <div class="gw-navigation__grid">
                        <gw-nav
                            class="gw-navigation__primary-nav gw-nav--large"
                            :items="mainMenu"
                        />

                        <div class="gw-navigation__sales-contact">
                            <gw-block
                                v-if="salesContact.contact"
                                :contact="salesContact.contact[0]"
                            >
                                <template slot="title">{{ salesContact.heading }}</template>
                                <!--eslint-disable-next-line vue/no-v-html-->
                                <template slot="body"><div v-html="salesContact.description" /></template>
                            </gw-block>
                        </div>

                        <gw-nav
                            class="gw-navigation__secondary-nav gw-nav--small"
                            :items="secondaryMenu"
                        >
                            <template slot="custom-item">
                                <gw-currency-switch />
                            </template>
                        </gw-nav>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwNav from '~/patterns/molecules/nav/nav.vue';
import GwBlock from '~/patterns/atoms/block/presets/nav-block.vue';
import GwCurrencySwitch from '~/patterns/molecules/currency-switch/currency-switch.vue';
import GwButton from '~/patterns/atoms/button/button.vue';

import getMainMenu from '~/graphql/queries/getMainMenu.graphql';

export default {
    components: {
        GwIcon,
        GwNav,
        GwBlock,
        GwButton,
        GwCurrencySwitch
    },
    props: {
        darkMode: {
            type: Boolean,
            default: true
        },
        elements: {
            type: Array,
            default: () => { return []; }
        }
    },
    data() {
        return {
            open: false,
            mainMenu: [],
            secondaryMenu: [],
            tertiaryMenu: [],
            salesContact: [],
            headerContactInfo: []
        };
    },
    async fetch() {
        const site = this.$config.appMultisiteHandle,
            { data } = await this.$gql.executeQuery(getMainMenu, { site });

        if (data && data.globalSets.length > 0) {
            // Map globalSets to data
            data.globalSets.forEach((set) => {
                if (this[set.handle]) {
                    if (set.entryLinks) {
                        this[set.handle] = set.entryLinks;
                    } else {
                        this[set.handle] = set;
                    }
                }
            });
        }
    },
    computed: {
        showContact() {
            return this.elements.includes('contact');
        },
        showTertiaryNav() {
            return this.elements.includes('tertiary-menu');
        },
        showNavigation() {
            return this.elements.includes('menu-button');
        },
        showEventButton() {
            return this.elements.includes('event-button');
        }
    },
    watch: {
        '$route'() {
            if (this.open) {
                this.closeOverlay();
            }
        }
    },
    beforeDestroy() {
        // Always remove no-scroll
        if (document.body.classList.contains('no-scroll')) {
            document.body.classList.remove('no-scroll');
        }
    },
    methods: {
        toggleOverlay() {
            this.open = !this.open;

            if (process.client) {
                if (this.open) {
                    // Reset nav scroll on open
                    const navWrapper = document.getElementsByClassName('gw-navigation__wrapper')[0];
                    navWrapper.scrollTop = 0;

                    document.body.classList.add('no-scroll');
                } else if (document.body.classList.contains('no-scroll')) {
                    document.body.classList.remove('no-scroll');
                }
            }
        },
        closeOverlay() {
            this.open = false;

            if (process.client) {
                if (document.body.classList.contains('no-scroll')) {
                    document.body.classList.remove('no-scroll');
                }
            }
        },

        formatPhone(phone) {
            if (!phone) {
                return '';
            }

            // +31 (0)20 2402244 -> 0031202402244
            const formatted = phone
                .replace('+', '00')
                .replace('(0)', '')
                .replace(/ /g, '');

            return formatted;
        }
    }
};
</script>

<style src="./navigation.less" lang="less"></style>
