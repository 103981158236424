<template>
    <gw-default-template>
        <gw-page-container>
            <gw-page-title
                class="gw-page-title--checkout gw-page-title--centered gw-page-title--standalone"
                :title="errorMessage"
            />
        </gw-page-container>

        <template slot="default">
            <div v-if="(error && error.statusCode === 404) || notFound" class="template__lead">
                <div>{{ $t('not-found.message') }}</div>
                <div>Go back to the <nuxt-link to="/">homepage</nuxt-link> or <nuxt-link to="/contact">contact support</nuxt-link>.</div>
            </div>
            <div v-else class="template__lead">
                <div>{{ $t('error.title') }}</div>
                <div>Try refreshing the page, go back to the <nuxt-link to="/">homepage</nuxt-link> or <nuxt-link to="/contact">contact support</nuxt-link>.</div>
            </div>
        </template>

        <gw-backdrop class="gw-backdrop--static" />
    </gw-default-template>
</template>

<script>
import GwDefaultTemplate from '~/patterns/templates/default';
import GwPageTitle from '~/patterns/molecules/page-title/page-title.vue';
import GwBackdrop from '~/patterns/atoms/backdrop/backdrop';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';

export default {
    components: {
        GwBackdrop,
        GwDefaultTemplate,
        GwPageTitle,
        GwPageContainer
    },
    props: {
        notFound: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed: {
        errorMessage() {
            return (this.error && this.error.statusCode === 404) || this.notFound ? this.$t('not-found.message') : this.$t('error.title');
        }
    }
};
</script>
