<template>
    <div class="gw-header-wrapper">
        <header
            class="gw-header"
            :class="{
                'gw-header--dark-mode': darkMode,
                'gw-header--transparent': transparent
            }"
        >
            <gw-page-container>
                <div class="gw-header__wrapper">
                    <nuxt-link
                        :to="$route.path === '/' ? '' : '/'"
                    >
                        <img
                            v-if="darkMode || transparent"
                            class="gw-header__logo"
                            src="/logos/Logo-Gladwell-Dark.svg"
                        >
                        <img
                            v-else
                            class="gw-header__logo"
                            src="/logos/Logo-Gladwell.svg"
                        >
                    </nuxt-link>
                    <gw-navigation :dark-mode="darkMode || transparent" :elements="elements">
                        <template slot="button">
                            <slot name="button" />
                        </template>
                    </gw-navigation>
                </div>
            </gw-page-container>
        </header>
        <div v-if="!transparent" class="gw-header__spacer" />
    </div>
</template>

<script>
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwNavigation from '~/patterns/organisms/navigation/navigation.vue';

export default {
    components: {
        GwPageContainer,
        GwNavigation
    },
    props: {
        darkMode: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        elements: {
            type: Array,
            default: () => { return ['contact', 'tertiary-menu', 'menu-button']; }
        }
    }
};
</script>

<style lang="less" src="./header.less" />
