<template>
    <div class="layout">
        <gw-header
            :elements="['contact', 'tertiary-menu', 'menu-button']"
            dark-mode
        />

        <div class="layout__main">
            <nuxt />
        </div>

        <gw-footer />
    </div>
</template>

<script>
import favicons from '~/mixins/favicons';
import GwHeader from '~/patterns/organisms/header/header.vue';
import GwFooter from '~/patterns/organisms/footer/footer.vue';

export default {
    components: {
        GwHeader,
        GwFooter
    },
    mixins: [favicons]
};
</script>

<style lang="less" scoped>
    .layout {
        .theme-dark();
    }
</style>
