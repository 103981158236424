<template>
    <div class="gw-page-title">
        <slot name="subtitle" />
        <h1 class="gw-page-title__primary">
            {{ title }}
            <slot name="icon" />
        </h1>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="less" src="./page-title.less"></style>
