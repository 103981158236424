const env = process.env.APP_ENV || 'development',

    config = {
        sites: {
            nl: {
                name: 'Gladwell Academy',
                description: 'Gladwell Academy ondersteunt jouw digitale transformatie met trainingen in Agile, Scrum, SAFe, bedrijfsinnovatie en informatiebeheer.',
                siteHandle: 'nl',
                locale: 'nl',
                currencies: ['EUR', 'USD']
            },
            in: {
                name: 'Gladwell Academy',
                description: 'Gladwell Academy trains professionals at the intersection of management, IT and innovation. View our Agile & SAFe training portfolio.',
                siteHandle: 'in',
                locale: 'en-IN',
                currencies: ['INR', 'USD']
            },
            en: {
                name: 'Gladwell Academy',
                description: 'Gladwell Academy trains professionals at the intersection of management, IT and innovation. View our Agile & SAFe training portfolio.',
                siteHandle: 'en',
                locale: 'en',
                currencies: ['EUR', 'USD']
            },
            de: {
                name: 'Gladwell Academy',
                description: '',
                siteHandle: 'de',
                locale: 'de',
                currencies: ['EUR', 'USD']
            },
            fr: {
                name: 'Gladwell Academy',
                description: '',
                siteHandle: 'fr',
                locale: 'fr',
                currencies: ['EUR', 'USD']
            }
        }
    },

    localizedConfigs = {
        production: require('./production').default,
        rootnet: require('./production').default,
        staging: require('./staging').default,
        development: require('./development').default
    },

    localizedConfig = localizedConfigs[env];

export function getSiteConfig(handle) {
    if (config.sites[handle]) {
        return config.sites[handle];
    }

    throw new Error('Could not find a matching site. Make sure you have provide a valid APP_MULTISITE_HANDLE env-variable.');
}

export default Object.assign(config, localizedConfig);
