<template>
    <gw-card
        class="gw-cta-card"
        :data-orientation="imageAlignment"
    >
        <template v-if="imageAlignment === 'left'" slot="before">
            <slot name="image" />
        </template>

        <template slot="default">
            <slot name="default" />

            <div v-if="!!$slots.button" class="gw-cta-card__button">
                <slot name="button" />
            </div>
        </template>

        <template v-if="imageAlignment === 'right'" slot="after">
            <slot name="image" />
        </template>
    </gw-card>
</template>

<script>
import GwCard from '~/patterns/atoms/card/card.vue';

export default {
    components: {
        GwCard
    },
    props: {
        program: {
            type: Object,
            default: () => { return {}; }
        },
        imageAlignment: {
            type: String,
            default: 'left'
        }
    }
};
</script>

<style src="./cta-card.less" lang="less"></style>
